@import '../../../sass/utils/variables';

#form-recovery {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 65%;
    align-items: stretch;
    margin: 1.5rem auto;

    &>.custom-modal-error {
        color: $danger-color;
        font-weight: 600;
        font-size: 1.2em;
        margin: 1rem 0;
        padding: 20px;
        filter: brightness(1.5);
        -webkit-filter: brightness(1.5);
        -moz-filter: brightness(1.5);
        -o-filter: brightness(1.5);
        -ms-filter: brightness(1.5);
    }

    &>.form-recovery-input-email {
        margin: 1rem 0 0.5rem 0;
    }

    &>.form-recovery-input-password {
        margin: 0.5rem 0 1rem 0;

        & svg[data-icon="eye"]:hover,
        & svg[data-icon="eye-slash"]:hover {
            cursor: pointer;
        }
    }

    &>button[type="submit"] {
        margin-top: 1.5rem;
        padding: 0.5rem 1rem;
    }

    &>.form-recovery-btn-recovery {
        margin: 1rem 0;
        color: rgba($dark-grey, 0.8);
    }

    &>.custom-alert-error {
        background-color: $danger-color;
        font-weight: 600;
        font-size: 1em;
        margin: 0;
        margin-bottom: 15px;
        padding: 20px;
        filter: brightness(1.5);
        -webkit-filter: brightness(1.5);
        -moz-filter: brightness(1.5);
        -o-filter: brightness(1.5);
        -ms-filter: brightness(1.5);
    }
}