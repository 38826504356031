@import "../../../sass/utils/variables";

.custom-modal-parent {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 9999;
    padding: 0 !important;
    animation: fade-in-background 0.5s forwards;
}

.custom-modal {
    width: 600px;
    height: 100%;
    background-color: #fff;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.custom-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: auto;

    &>hr {
        margin: 10px 0;
    }
}

.custom-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.custom-modal-title {
    display: flex;
    flex-direction: column;
    align-items: center;


    &>#title-element {
        font-weight: 600;
        font-size: 1.8em;
        margin-bottom: 0.5rem;
        max-width: 70%;
        text-align: center;
    }

    &>#subtitle-element {
        font-size: 1em;
        color: #777;
        font-weight: 100;
        margin-bottom: 0.5rem;
        max-width: 70%;
        text-align: center;
    }

    &>#custom-modal-icon-container {
        margin: 3rem 0;
        padding: 25px 25px 21px 25px; // HACK: cant apply margin top to svg directly
        background-color: rgba($main-color-rgb, 0.3);
        border-radius: 8px;

        &>svg {
            width: 50px;
            height: 50px;
            fill: rgb($main-color-rgb);
            color: rgb($main-color-rgb);
            filter: brightness(0.8);
            -webkit-filter: brightness(0.8);
            -moz-filter: brightness(0.8);
            -o-filter: brightness(0.8);
            -ms-filter: brightness(0.8);
        }
    }
}

.custom-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.slide-in {
    animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
}

.fade-in {
    animation: fade-in 0.5s forwards;
}

.fade-out {
    animation: fade-out 0.5s forwards;
}

@keyframes slide-in {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fade-in-background {
    from {
        background-color: rgba(0, 0, 0, 0);
    }

    to {
        background-color: rgba(0, 0, 0, 0.3);
    }
}

@media screen and (max-width: 768px) {
    .custom-modal-parent {
        justify-content: flex-start;
    }

    .custom-modal {
        width: 100%;
    }
}