#confirm-password-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 75%;
    align-items: stretch;
    margin: 1.5rem auto;

    & > *:first-child {
        margin-bottom: 1rem;
    }

    & > *:last-child {
        margin-top: 1rem;
    }
}