@import "../../../sass/utils/variables";

.customer-menu-container > * {
    transition: none !important;
}

.customer-menu-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: fit-content;
}

.safe-area {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#login-state {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    margin-left: auto;
}

#login-state:hover {
    cursor: pointer;
}

#paraf {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    background-color: $main-color;
    margin-right: 10px;
}

#paraf * {
    color: $main-color;
    fill: $main-color;
    font-size: 12px;
    filter: brightness(3);
    -webkit-filter: brightness(3);
    -moz-filter: brightness(3);
    -o-filter: brightness(3);
    -ms-filter: brightness(3);
}

.fake-btn {
    width: 16px;
    margin: 0 5px;
}

.collapseCustomerMenu {
    transition: all ease-in-out 0.3s !important;
    position: absolute;
    top: 75px;
    right: 0;
    width: 250px;
    font-size: 14px;
    margin: 0 0.5rem 0 auto;
    background: white;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    z-index: 1000;
}

.collapse-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 5px;
    margin: 0.25rem 0.5rem;

    color: $link-color;
    fill: $link-color;
}

.collapse-item:hover {
    background-color: rgba($main-color-rgb, 0.2);
    cursor: pointer;
    color: $main-color !important;
    fill: $main-color !important;
}

.collapse-item *:nth-child(odd) {
    margin-right: 5px;
}

.collapse-item *:nth-child(even) {
    margin-left: 5px;
}

#paraf .icon-container {
    font-size: 14px !important;
    width: 14px !important;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &>svg {
        width: 14px;
        height: auto;
        margin: 0;
    }
}

.collapseCustomerMenu a {
    color: $link-color !important;
    fill: $link-color !important;
}

.return-home-button {
    transition: all ease-in-out 0.1s !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    margin: 0;
    background-color: rgba($main-color-rgb, 0.2);
    color: $main-color;
    fill: $main-color;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 14px;
    font-weight: 600;
    margin-right: auto;
    margin-left: 1.5rem;

    &>span {
        margin-left: 5px;
        filter: brightness(3);
        -webkit-filter: brightness(0.9);
        -moz-filter: brightness(0.9);
        -o-filter: brightness(0.9);
        -ms-filter: brightness(0.9);
    }

    &>svg {
        width: 16px;
        height: auto;
        filter: brightness(3);
        -webkit-filter: brightness(0.9);
        -moz-filter: brightness(0.9);
        -o-filter: brightness(0.9);
        -ms-filter: brightness(0.9);
    }
}

.return-home-button:hover {
    cursor: pointer;
    background-color: $main-color;
    color: white;
    fill: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    &>span {
        filter: brightness(3);
        -webkit-filter: brightness(1);
        -moz-filter: brightness(1);
        -o-filter: brightness(1);
        -ms-filter: brightness(1);
    }

    &>svg {
        filter: brightness(3);
        -webkit-filter: brightness(1);
        -moz-filter: brightness(1);
        -o-filter: brightness(1);
        -ms-filter: brightness(1);
    }
}

.return-home-text {
    display: none;
}

@media screen and (min-width: 550px) {
    .return-home-text {
        display: inline;
    }
}

@media screen and (min-width: 768px) {
    #login-state {
        margin-left: 1rem;
        margin-right: 1.5rem;
    }
}