
$main-color : var(--c-main);
$main-color-rgb : var(--c-main-rgb);
$link-color : var(--c-link);
$link-hover-color : var(--c-link-hover);
$bg-color : var(--bg-main);

$header-text-color : var(--header-text-color);
$header-background-color : var(--header-background-color);
$header-highlight-color : var(--header-highlight-color);

$light-gray : #f6f7f8;
$danger-color : #ff4f4a;
$dropdown-link-hover-bg: #dddaff;
$dark-grey: #777;

$logo-height: var(--logo-height);

$rightModalSaveButtonHeight: 65px;
$rightModalWidth: 700px;

//$primary:      var(--c-main-rgb) !default;
//$secondary:     $gray-600 !default;
//$success:       $main-color !default;
//$info:          $cyan !default;
//$warning:       $yellow !default;
//$danger:        $red !default;
//$light:         $gray-100 !default;
//$dark:          $gray-900 !default;